import SearchIcon from '../shared/icon/SearchIcon';
import FunnelIcon from '../shared/icon/FunnelIcon';
import SortIcon from '../shared/icon/SortIcon';
import TableViewMenu from './TableViewMenu';
import Popover from '../shared/popover/Popover';
import { useCallback, useMemo, useState } from 'react';
import { Input, InputStyle } from '../shared/form-control/Input';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { useTranslation } from 'react-i18next';
import TableViewFiltersOverview from './TableViewFiltersOverview';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import { useTableViewFilters } from '../../contexts/table-view/TableViewFilterContext';
import TableViewSortingOverview from './TableViewSortingOverview';
import NumbersIcon from '../shared/icon/NumberIcon';
import Tooltip from '../shared/Tooltip';
import { TableViewColumnConfiguration, mainViewId } from '../../models/TableView';
import DownloadIcon from '../shared/icon/DownloadIcon';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import Button, { ButtonType } from '../shared/form-control/Button';
import { FileUtils } from '../../utils/FileUtils';
import DataExportService from '../../services/DataExportService';
import { useNavigate } from 'react-router';
import { useProcessesHub } from '../../contexts/signalR/ProcessesContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';
import DateUtils from '../../utils/DateUtils';
import ModuleUtils from '../../utils/ModuleUtils';
import { useImportFilter } from '../../hooks/useImportFilter';
import { useCurrentClient } from '../../global-state/Clients';

const TableViewGlobalActions = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const {
    templateModuleSectionId,
    selectedTableView,
    selectedTemplateFormId,
    setSelectedTableView,
    renderedGQL,
    clientModule,
    clientModuleSectionName,
  } = useTableView();
  const { t } = useTranslation(['table-view', 'data-import-export']);
  const { searchTerm, setSearchTerm } = useTableViewFilters();
  const { hasImportFilter } = useImportFilter();
  const toaster = useToasts();
  const currentClient = useCurrentClient((x) => x.value);
  const navigate = useNavigate();
  const { listenToProcess } = useProcessesHub();
  const hasPermission = usePermissions();

  const canFilter = useMemo(() => {
    return !!selectedTableView?.columnConfigurations;
  }, [selectedTableView?.columnConfigurations]);

  const disabledCss = useMemo(() => {
    return !canFilter ? 'pointer-events-none opacity-40' : '';
  }, [canFilter]);

  const toggleSearch = useCallback(() => {
    if (!searchTerm) {
      setIsSearchExpanded(!isSearchExpanded);
    }
  }, [searchTerm, isSearchExpanded]);

  const onClearSearch = useCallback(() => {
    setSearchTerm('');
    setIsSearchExpanded(false);
  }, [setSearchTerm]);

  const selectedConfig = useMemo(() => {
    if (!selectedTemplateFormId || !selectedTableView?.columnConfigurations) return null;
    const columnConfigKey =
      selectedTableView?.id === mainViewId && Object.keys(selectedTableView?.columnConfigurations).length === 1 ? mainViewId : selectedTemplateFormId;
    return selectedTableView.columnConfigurations[columnConfigKey];
  }, [selectedTableView.columnConfigurations, selectedTableView?.id, selectedTemplateFormId]);

  const totalFilters = useMemo(() => {
    const total = hasImportFilter ? 1 : 0;
    if (!selectedConfig) return total;
    return total + (selectedConfig.columns ?? []).filter((col) => col.filter).length;
  }, [hasImportFilter, selectedConfig]);

  const totalSorts = useMemo(() => {
    if (!selectedConfig) return 0;
    return (selectedConfig.columns ?? []).filter((col) => col.sortDirection !== null && col.sortDirection !== undefined).length;
  }, [selectedConfig]);

  const setEnableNumbering = useCallback(() => {
    if (!setSelectedTableView || !selectedTemplateFormId) return;

    setSelectedTableView((prev) => {
      if (!prev) return prev;

      const columnConfigurations = prev.columnConfigurations ?? {};
      const toggleIndexNumbering = (config: TableViewColumnConfiguration) => ({
        ...config,
        enableIndexNumbering: !config?.enableIndexNumbering,
      });

      const updatedConfigurations = { ...columnConfigurations };

      if (selectedTableView.id === mainViewId) {
        // Clone the main view configuration into the selected template configuration if not already present
        if (!columnConfigurations[selectedTemplateFormId]) {
          updatedConfigurations[selectedTemplateFormId] = {
            ...columnConfigurations[mainViewId],
            id: undefined,
            enableIndexNumbering: !columnConfigurations[mainViewId]?.enableIndexNumbering,
          };
        } else {
          updatedConfigurations[selectedTemplateFormId] = toggleIndexNumbering(columnConfigurations[selectedTemplateFormId]);
        }
      } else {
        updatedConfigurations[selectedTemplateFormId] = toggleIndexNumbering(columnConfigurations[selectedTemplateFormId] || {});
      }

      return {
        ...prev,
        columnConfigurations: updatedConfigurations,
      };
    });
  }, [selectedTableView.id, selectedTemplateFormId, setSelectedTableView]);

  const triggerDownload = useCallback(() => {
    if (!renderedGQL) return;

    const failedToast = () =>
      toaster.addToast({
        title: t('data-import-export:export.toasters.failure.title'),
        description: t('data-import-export:export.toasters.failure.description'),
        type: ToastType.ERROR,
      });

    DataExportService.createTableViewExport({
      fileName: clientModule
        ? `${DateUtils.formatDateTime(DateUtils.now, false, true).replaceAll(/[^a-zA-Z0-9\s]/g, '')}_${ModuleUtils.getName(clientModule.templateModule)}_${clientModuleSectionName}.csv`.replaceAll(
            ' ',
            '',
          )
        : 'export.csv',
      query: renderedGQL.query,
      variables: renderedGQL.variables,
      tableViewId: selectedTableView.id,
    })
      .then((res) => {
        toaster.addToast({
          title: t('data-import-export:export.toasters.started.title'),
          description: t('data-import-export:export.toasters.started.description'),
          type: ToastType.INFO,
          expiresInMs: 3000,
          slots: {
            button: (
              <Button
                type={ButtonType.TERTIARY}
                onClick={() => {
                  navigate(`/clients/${currentClient?.id}/organisation#data-exports`);
                }}
              >
                {t('data-import-export:export.toasters.started.button')}
              </Button>
            ),
          },
        });
        listenToProcess<{ fileId: string; fileName: string }>(res.data.jobId)
          .then((res) => {
            FileUtils.downloadFile({ id: res.fileId, name: res.fileName, addExtension: true });
            toaster.addToast({
              title: t('data-import-export:export.toasters.success.title'),
              description: t('data-import-export:export.toasters.success.description'),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
              slots: {
                button: (
                  <div className="flex">
                    <Button
                      type={ButtonType.TERTIARY}
                      onClick={() => {
                        navigate(`/clients/${currentClient?.id}/organisation#data-exports`);
                      }}
                    >
                      {t('data-import-export:export.toasters.success.view-all-button')}
                    </Button>
                  </div>
                ),
              },
            });
          })
          .catch(() => {
            failedToast();
          });
      })
      .catch(() => {
        failedToast();
      });
  }, [clientModule, clientModuleSectionName, currentClient?.id, listenToProcess, navigate, renderedGQL, selectedTableView.id, t, toaster]);

  const buttonContainerClasses =
    'flex items-center justify-center rounded-md bg-gray-6 text-gray-2 max-h-[30px] h-[30px] min-w-[30px] px-1 cursor-pointer hover:bg-gray-5';
  const buttonClasses = `${buttonContainerClasses} w-9 h-8 py-1 `;
  return (
    <div className="piped-items flex items-center gap-2">
      {templateModuleSectionId && (
        <div className="!pr-1">
          <TableViewMenu />
        </div>
      )}
      <div className="flex items-center !pl-4 !pr-1">
        <Tooltip text={t('global-actions.tooltips.numbering')}>
          {(tooltip) => (
            <div {...tooltip}>
              {
                <NumbersIcon
                  className={`${buttonClasses} ${selectedConfig?.enableIndexNumbering ? '!bg-primary-2 bg-opacity-25' : ''}`}
                  onClick={setEnableNumbering}
                />
              }
            </div>
          )}
        </Tooltip>
      </div>
      <div className={`${disabledCss} flex flex-shrink-0 items-center gap-2 !pr-1`}>
        <Popover content={<TableViewFiltersOverview />} placement="bottom-start">
          {(popover, toggle) => (
            <div
              {...popover}
              className={`${buttonContainerClasses} ${totalFilters > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
              {...mouseAndKeyboardCallbackProps(toggle)}
            >
              <Tooltip text={t('global-actions.tooltips.filter')}>
                {(tooltip) => <div {...tooltip}>{<FunnelIcon className="h-6 w-5" />}</div>}
              </Tooltip>
              {totalFilters > 0 && <div className="px-2 font-medium">{t('filters.count', { count: totalFilters })}</div>}
            </div>
          )}
        </Popover>

        <Popover content={<TableViewSortingOverview />} placement="bottom-start">
          {(popover, toggle) => (
            <div
              {...popover}
              className={`${buttonContainerClasses} ${totalSorts > 0 ? '!bg-primary-2 bg-opacity-25' : ''}`}
              {...mouseAndKeyboardCallbackProps(toggle)}
            >
              <Tooltip text={t('global-actions.tooltips.sorting')}>{(tooltip) => <div {...tooltip}>{<SortIcon className="h-6 w-5" />}</div>}</Tooltip>
              {totalSorts > 0 && <div className="px-2 font-medium">{t('sorting.count', { count: totalSorts })}</div>}
            </div>
          )}
        </Popover>
      </div>
      <div className={`${disabledCss} flex flex-shrink-0 items-center gap-2 !pl-4 !pr-1`}>
        <div className="flex items-center">
          {(!hasPermission(Roles.ExternalAuditor) || hasPermission(Roles.Management)) && (
            <DownloadIcon className={buttonClasses} onClick={triggerDownload} />
          )}
        </div>
        <div className="flex items-center">
          <Tooltip text={t('global-actions.tooltips.search')}>
            {(tooltip) => <div {...tooltip}>{<SearchIcon className={buttonClasses} onClick={toggleSearch} />}</div>}
          </Tooltip>
          <div className={`transition-width px-1 duration-300 ease-in-out ${isSearchExpanded ? 'w-64 opacity-100' : 'w-0 opacity-0'} mr-5`}>
            <Input
              style={InputStyle.MINIMAL}
              placeholder={t('table-view:filter-menu.search')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onClear={onClearSearch}
              autoFocus={isSearchExpanded}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableViewGlobalActions;
