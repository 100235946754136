import { ComponentRef, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ClientModule } from '../../models/ClientModule';
import { Roles } from '../../models/Role';
import ModuleService from '../../services/ModuleService';
import ModuleUtils from '../../utils/ModuleUtils';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import StaticBreadCrumb from '../../components/shared/breadcumb/StaticBreadCrumb';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import NewTaskButton from '../../components/shared/NewTaskButton';
import { ClientForm } from '../../models/ClientForm';
import usePermissions from '../../hooks/permissions/usePermissions';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../../components/shared/modal/variants/ConfirmationModal';
import TabButton from '../../components/shared/tab-strip/TabButton';
import LanguageUtils from '../../utils/LanguageUtils';
import ContextMenu from '../../components/shared/ContextMenu';
import { ContextMenuItem } from '../../components/shared/ContextMenu';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import TrashIcon from '../../components/shared/icon/TrashIcon';
import ExportIcon from '../../components/shared/icon/ExportIcon';
import { SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import { DataJobSourceType } from '../../models/DataImport';
import { useFeatureFlags } from '../../contexts/FeatureFlagContext';
import TableViewProvider from '../../components/table-view/TableViewProvider';
import { useCurrentClient } from '../../global-state/Clients';

const ModuleV2: FC = () => {
  const params = useParams<{ moduleId: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['module', 'module-list', 'common']);
  const modulesListRef = useRef<HTMLDivElement>(null);
  const { featureFlags } = useFeatureFlags();

  const client = useCurrentClient((x) => x.value);
  const [clientModule, setClientModule] = useState<ClientModule | null>(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showDeleteConfim, setShowDeleteConfim] = useState(false);
  const [selectedModuleSectionId, setSelectedModuleSectionId] = useState('');

  const pageContentRef = useRef<HTMLDivElement>(null);
  const exportProvider = useRef<ComponentRef<typeof SelectItemContextProvider>>(null);

  useEffect(() => {
    const currentHash = location.hash.replace('#', '');
    setSelectedModuleSectionId(currentHash && currentHash !== 'all' ? currentHash : '');
  }, [location.hash]);
  const hasPermission = usePermissions();

  const loadModule = useCallback(() => {
    return ModuleService.getModule(params.moduleId as string).then((res) => {
      setClientModule(res.data);
    });
  }, [params.moduleId]);

  useEffect(() => {
    loadModule();
  }, [loadModule]);

  const deleteModule = useCallback(() => {
    ModuleService.deleteModule(clientModule?.id || '').then(() => {
      setShowDeleteConfim(false);
      navigate(`/clients/${client?.id}/modules`);
    });
  }, [client?.id, clientModule?.id, navigate]);

  const onTaskCreated = useCallback(
    (clientForm: ClientForm) => {
      navigate(`/clients/${clientForm.clientId}/forms/${clientForm.id}`);
    },
    [navigate],
  );

  const moduleDescription = useMemo(() => (clientModule ? ModuleUtils.getDescription(clientModule) : ''), [clientModule]);

  const contextItems = useMemo<ContextMenuItem[]>(() => {
    return [
      {
        title: t('module:context.more-info'),
        icon: <InfoIcon className="h-6 w-6" />,
        onClick: () => setShowInfoModal(true),
        hide: !moduleDescription,
      },
      {
        title: t('module:context.export'),
        icon: <ExportIcon className="h-6 w-6" />,
        onClick: () => {
          exportProvider.current?.setShowDataExportModal(true);
          exportProvider.current?.selectAll(true);
          exportProvider.current?.setFilter({ clientModuleId: clientModule?.id });
          exportProvider.current?.setSource({
            name: LanguageUtils.getTranslation('name', clientModule?.translations ?? {}),
            id: clientModule?.id,
            type: DataJobSourceType.Module,
          });
        },
        hide: !hasPermission(Roles.TeamMember) || !featureFlags.dataExport,
      },
      {
        title: t('module:context.delete'),
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => setShowDeleteConfim(true),
        hide: !hasPermission(Roles.SuperAdmin),
      },
    ];
  }, [clientModule?.id, clientModule?.translations, featureFlags.dataExport, hasPermission, moduleDescription, t]);

  useEffect(() => {
    exportProvider.current?.reset();
  }, [selectedModuleSectionId]);

  const selectedModuleSectionName = useMemo(() => {
    if (!clientModule) {
      return '';
    }
    if (!selectedModuleSectionId) {
      return t('module:sections-all');
    }
    const section = clientModule.templateModule.sections.find((x) => x.id === selectedModuleSectionId);
    return section ? LanguageUtils.getTranslation('name', section.translations) : '';
  }, [clientModule, selectedModuleSectionId, t]);

  const moduleName = useMemo(() => {
    if (!clientModule) {
      return '';
    }
    return ModuleUtils.getName(clientModule.templateModule);
  }, [clientModule]);

  const templateSections = useMemo(() => {
    return clientModule?.templateModule.sections.filter((x) => x.forms.length > 0) ?? [];
  }, [clientModule?.templateModule.sections]);

  return (
    <>
      <div className="flex h-full flex-grow flex-col">
        <TopNavPortal>
          <StaticBreadCrumb
            returnPath={`/clients/${client?.id}/modules`}
            breadCrumbs={[{ name: t('module-list:heading'), path: `/clients/${client?.id}/modules` }]}
            currentStepName={moduleName}
          />
        </TopNavPortal>

        <div className="page-content flex flex-col overflow-y-auto px-6" ref={pageContentRef}>
          <div className="flex pt-6">
            <div className="flex flex-1 flex-row items-center">
              <Heading size={HeadingSize.H2} actualSize={HeadingSize.H3}>
                {moduleName}
              </Heading>
            </div>
          </div>
          <div className={`flex flex-grow`} ref={modulesListRef}>
            <div className={`max-w-full flex-grow transition-all duration-500 ease-in-out`}>
              <div className="relative w-full">
                <div className="pb-4 pt-0">
                  <div className="-mx-4 mt-0 flex-grow">
                    {clientModule && (
                      <TableViewProvider
                        clientModuleId={clientModule?.id}
                        clientModuleSectionId={clientModule.sections.find((x) => x.templateId === selectedModuleSectionId)?.id}
                        templateModuleSectionId={selectedModuleSectionId}
                        sectionName={selectedModuleSectionName}
                        exportProvider={exportProvider}
                        clientModule={clientModule}
                      >
                        <TableViewProvider.Slot name="Tabs">
                          <div className="mb-4 ml-4 mt-2 flex max-w-full flex-wrap overflow-auto">
                            <TabButton
                              selected={!selectedModuleSectionId}
                              borderless
                              onClick={() => {
                                navigate({ hash: 'all' }, { replace: true });
                              }}
                            >
                              {t('module:sections-all')}
                            </TabButton>

                            {templateSections.map((x) => (
                              <TabButton
                                key={x.id}
                                selected={selectedModuleSectionId === x.id}
                                borderless
                                onClick={() => {
                                  navigate({ hash: x.id }, { replace: true });
                                }}
                              >
                                {LanguageUtils.getTranslation('name', x.translations) || t('module:unnamed-section')}
                              </TabButton>
                            ))}
                          </div>
                        </TableViewProvider.Slot>
                        <TableViewProvider.Slot name="NewButton">
                          <div className="flex items-center gap-2">
                            {(hasPermission(Roles.Management) || !hasPermission(Roles.ExternalAuditor)) && (
                              <NewTaskButton
                                moduleId={clientModule?.id}
                                moduleTemplateId={clientModule?.templateModule.id}
                                onTaskCreated={onTaskCreated}
                              />
                            )}
                            <ContextMenu items={contextItems} />
                          </div>
                        </TableViewProvider.Slot>
                      </TableViewProvider>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalContext.Provider value={{ open: showInfoModal, onClose: () => setShowInfoModal(false) }}>
        <ConfirmationModal
          title={t('module:info-modal.heading')}
          description={moduleDescription}
          confirmText={t('module:buttons.close')}
          onConfirm={() => setShowInfoModal(false)}
        />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ open: showDeleteConfim, onClose: () => setShowDeleteConfim(false) }}>
        <ConfirmationModal
          title={t('module:delete-modal.heading')}
          description={t('module:delete-modal.body')}
          cancelText={t('module:buttons.cancel')}
          confirmText={t('module:context.delete')}
          onCancel={() => setShowDeleteConfim(false)}
          onConfirm={deleteModule}
          alt
        />
      </ModalContext.Provider>
    </>
  );
};

export default ModuleV2;
