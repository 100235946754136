import { ComponentRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import TableViewTopContainer from './TableViewTopContainer';
import EmptyView from './EmptyView';
import { RenderedGQL, ResultCounts, TableViewContext, TableViewContextType } from '../../contexts/table-view/TableViewContext';
import { ColumnConfig, SortDirection, allTemplatesId, freezeLineId, mainViewConfig, mainViewId, newViewId } from '../../models/TableView';
import { ColumnType, TableView, TableViewColumnConfiguration, TableViewColumnConfigurationResponse } from '../../models/TableView';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import TableViewConfigModal from './TableViewConfigModal';
import { useTranslation } from 'react-i18next';
import TableViewService from '../../services/TableViewService';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import RouteGuard from '../shared/RouteGuard';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import useSlot from '../../hooks/useSlots';
import StandardModal from '../shared/modal/variants/StandardModal';
import { Input } from '../shared/form-control/Input';
import Checkbox from '../shared/form-control/Checkbox';
import RadioButton from '../shared/form-control/RadioButton';
import HelpCircleIcon from '../shared/icon/HelpCircleIcon';
import Tooltip from '../shared/Tooltip';
import TableViewData from './TableViewData';
import ObjectUtils from '../../utils/ObjectUtils';
import { TableViewScrollProvider } from '../../contexts/table-view/TableViewScrollContext';
import { TableViewFilterProvider } from '../../contexts/table-view/TableViewFilterContext';
import { FilterValue } from '../../models/TableViewFilters';
import { SelectItemContextProvider } from '../../contexts/select-items/SelectItemsContext';
import { ClientModule } from '../../models/ClientModule';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';

type Props = {
  clientModuleId: string;
  clientModuleSectionId: string | null | undefined;
  templateModuleSectionId: string | null;
  sectionName: string;
  exportProvider: RefObject<ComponentRef<typeof SelectItemContextProvider>>;
  clientModule?: ClientModule | null;
};

const TableViewProvider = withSlot<Props, SlotDefinitions<['NewButton', 'Tabs']>>((props) => {
  const { templateModuleSectionId, clientModuleId, sectionName, clientModuleSectionId, exportProvider, clientModule } = props;

  const NewButtonSlot = useSlot(props, 'NewButton');
  const TabsSlot = useSlot(props, 'Tabs');

  const { t } = useTranslation(['table-view']);
  const client = useCurrentClient((x) => x.value);
  const mainView = useMemo<TableView>(
    () => ({
      id: mainViewId,
      templateModuleSectionId,
      clientId: client?.id || '',
      name: t('views.main-view'),
      columnConfigurations: mainViewConfig,
      isDefault: true,
      isFavourite: false,
      isOrganisationDefault: false,
      isPersonalDefault: false,
      defaults: [],
    }),
    [client?.id, templateModuleSectionId, t],
  );

  const [selectedOriginalTableView, setSelectedOriginalTableView] = useState<TableView>(mainView);
  const [selectedTableView, setSelectedTableView] = useState<TableView>(mainView);
  const [availableViews, setAvailableViews] = useState<TableView[]>([]);
  const isNewUnsavedView = useMemo(() => selectedTableView.id === newViewId, [selectedTableView]);
  const [showDiscardConfim, setShowDiscardConfim] = useState(false);
  const [showTableViewConfigModal, setShowTableViewConfigModal] = useState(false);
  const [showSaveConfim, setShowSaveConfim] = useState(false);
  const [selectedTemplateFormId, setSelectedTemplateFormId] = useState<string | null>(null);
  const [resultCounts, setResultCounts] = useState<ResultCounts>({ count: 0, total: 0 });
  const [showRowNumbers, setShowRowNumbers] = useState<boolean>(false);
  const [renderedGQL, setRenderedGQL] = useState<RenderedGQL | null>(null);
  const currentUser = useCurrentUser((x) => x.value);
  const toaster = useToasts();
  const hasPermission = usePermissions();
  const isTeamLead = hasPermission(Roles.TeamLead);

  useEffect(() => {
    if (isTeamLead) return;

    if (showSaveConfim) {
      setSelectedTableView((prev) => ({ ...prev, userId: currentUser?.id, isOrganisationDefault: false }));
    }
  }, [currentUser?.id, isTeamLead, showSaveConfim]);

  const addFreezeColumn = useCallback((config: TableViewColumnConfigurationResponse) => {
    // Find the index of the last column with freeze = true
    const lastFreezeIndex = config.columns.reduce((lastIndex, column, index) => {
      return column.freeze ? index : lastIndex;
    }, -1);

    // Insert the new column after the last freeze column
    return [
      ...config.columns.slice(0, lastFreezeIndex + 1),
      { value: freezeLineId, freeze: true, type: ColumnType.Action },
      ...config.columns.slice(lastFreezeIndex + 1),
    ];
  }, []);

  const fetchTableViews = useCallback(
    (setSelectedView: boolean = false) => {
      if (!client) return;
      // Make sure we don't bring over any unwanted templates from the previous section
      const resetMainView = {
        ...mainView,
        columnConfigurations: { ...mainViewConfig },
      };

      if (!templateModuleSectionId || !clientModuleSectionId) {
        setAvailableViews([resetMainView]);
        setSelectedOriginalTableView(resetMainView);
        setSelectedTableView(resetMainView);
        return;
      }

      TableViewService.getTableViews(client.id, clientModuleSectionId).then((response) => {
        const updatedTableViews = response.data.map((tableView) => ({
          ...tableView,
          templateModuleSectionId: templateModuleSectionId,
          columnConfigurations: tableView.columnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
            acc[config.templateFormId] = {
              ...config,
              columns: addFreezeColumn(config),
            };
            return acc;
          }, {}),
        }));

        const mainViewDefault = updatedTableViews.flatMap((view) => view.defaults).find((defaultItem) => defaultItem.tableViewId === mainViewId);

        const defaultView =
          updatedTableViews.find((view) => view.isDefault && !view.isOrganisationDefault) ??
          updatedTableViews.find((view) => view.isDefault) ??
          resetMainView;

        if (mainViewDefault) {
          resetMainView.isDefault = true;
          resetMainView.isPersonalDefault = !!mainViewDefault?.userId;
          resetMainView.isOrganisationDefault = !mainViewDefault?.userId;
        } else {
          resetMainView.isDefault = !updatedTableViews.some((view) => view.isDefault);
        }

        if (setSelectedView) {
          const selectedView = resetMainView.isDefault ? resetMainView : defaultView;
          setSelectedOriginalTableView(ObjectUtils.DeepClone(selectedView));
          setSelectedTableView(ObjectUtils.DeepClone(selectedView));
        }
        setAvailableViews([resetMainView, ...updatedTableViews]);
      });
    },
    [addFreezeColumn, client, clientModuleSectionId, mainView, templateModuleSectionId],
  );

  useEffect(() => {
    fetchTableViews(true);
  }, [fetchTableViews]);

  const isDirty = useMemo(() => {
    return (
      JSON.stringify(selectedTableView) !== JSON.stringify(selectedOriginalTableView) &&
      !!selectedTableView.templateModuleSectionId &&
      selectedTemplateFormId !== allTemplatesId
    );
  }, [selectedOriginalTableView, selectedTableView, selectedTemplateFormId]);

  const onEditTableView = useCallback(() => {
    setShowTableViewConfigModal(true);
  }, []);

  const onDiscardTableView = useCallback(() => {
    setShowDiscardConfim(true);
  }, []);

  const doDiscard = useCallback(() => {
    setSelectedTableView(selectedOriginalTableView || mainView);

    setShowDiscardConfim(false);
  }, [mainView, selectedOriginalTableView]);

  const [modalHandlers, setModalHandlers] = useState<{ onSave?: () => void; onCancel?: () => void }>({});

  const onSaveTableView = useCallback(
    (asClone?: boolean) => {
      return new Promise<void>((resolve, reject) => {
        setShowSaveConfim(true);
        if (selectedTableView.id === mainViewId) {
          setSelectedTableView((prev) => ({ ...prev, name: t('views.new-view'), userId: currentUser?.id, isOrganisationDefault: false }));
        } else if (asClone) {
          const clonedView = ObjectUtils.DeepClone(selectedTableView);
          const clonedColumnConfigs = clonedView.columnConfigurations
            ? Object.keys(clonedView.columnConfigurations).reduce(
                (acc, key) => {
                  acc[key] = { ...clonedView.columnConfigurations![key], id: undefined };
                  return acc;
                },
                {} as Record<string, TableViewColumnConfiguration>,
              )
            : null;
          const newView: TableView = {
            ...clonedView,
            columnConfigurations: clonedColumnConfigs,
            id: newViewId,
            name: t('views.copy-of-view', { name: clonedView.name }),
            isDefault: false,
            isFavourite: false,
            isOrganisationDefault: false,
            isPersonalDefault: false,
            defaults: [],
          };
          setSelectedTableView(newView);
        }

        const handleSave = () => {
          setShowSaveConfim(false);

          // Doing it this way to make sure the selectedTableView is the latest,
          // because the state might have changed and the promise is resolved later
          setSelectedTableView((prevSelectedTableView) => {
            const isMainViewEdited = prevSelectedTableView.id === mainViewId;
            if (isNewUnsavedView || isMainViewEdited || asClone) {
              TableViewService.createTableView({
                clientModuleId: clientModuleId,
                templateModuleSectionId: prevSelectedTableView.templateModuleSectionId,
                clientId: prevSelectedTableView.clientId,
                name: prevSelectedTableView.name,
                userId: currentUser?.id,
                isPersonalDefault: prevSelectedTableView.isPersonalDefault,
                isOrganisationDefault: prevSelectedTableView.isOrganisationDefault,
                columnConfigurations: prevSelectedTableView.columnConfigurations
                  ? Object.entries(prevSelectedTableView.columnConfigurations)
                      .filter(([templateFormId]) => templateFormId !== mainViewId)
                      .map(([templateFormId, config]) => ({
                        templateFormId,
                        ...config,
                        columns: config.columns.filter(({ value }) => value !== freezeLineId),
                      }))
                  : [],
              })
                .then((response) => {
                  const newId = response.data.id;
                  const updatedColumnConfigurations = response.data.columnConfigurations;
                  const updatedTableView = {
                    ...prevSelectedTableView,
                    id: newId,
                    columnConfigurations: updatedColumnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
                      acc[config.templateFormId] = { ...config, columns: addFreezeColumn(config) };
                      return acc;
                    }, {}),
                  };
                  fetchTableViews();
                  setSelectedTableView(ObjectUtils.DeepClone(updatedTableView));
                  setSelectedOriginalTableView(ObjectUtils.DeepClone(updatedTableView));

                  toaster.addToast({
                    title: t('toasters.view.save.success.title'),
                    description: t('toasters.view.save.success.description', { name: updatedTableView.name }),
                    type: ToastType.SUCCESS,
                    expiresInMs: 5000,
                  });

                  resolve();
                })
                .catch(() => {
                  reject();
                });

              return prevSelectedTableView;
            } else {
              TableViewService.updateTableView(prevSelectedTableView.id, {
                name: prevSelectedTableView.name,
                userId: prevSelectedTableView.userId,
                isPersonalDefault: prevSelectedTableView.isPersonalDefault,
                isOrganisationDefault: prevSelectedTableView.isOrganisationDefault,
                columnConfigurations: prevSelectedTableView.columnConfigurations
                  ? Object.entries(prevSelectedTableView.columnConfigurations).map(([templateFormId, config]) => ({
                      templateFormId,
                      ...config,
                      columns: config.columns.filter(({ value }) => value !== freezeLineId),
                    }))
                  : [],
              })
                .then((response) => {
                  const updatedColumnConfigurations = response.data.columnConfigurations;
                  const updatedTableView = {
                    ...prevSelectedTableView,
                    columnConfigurations: updatedColumnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
                      acc[config.templateFormId] = { ...config, columns: addFreezeColumn(config) };
                      return acc;
                    }, {}),
                  };
                  fetchTableViews();
                  setSelectedTableView(ObjectUtils.DeepClone(updatedTableView));
                  setSelectedOriginalTableView(ObjectUtils.DeepClone(updatedTableView));

                  toaster.addToast({
                    title: t('toasters.view.save.success.title'),
                    description: t('toasters.view.save.success.description', { name: updatedTableView.name }),
                    type: ToastType.SUCCESS,
                    expiresInMs: 5000,
                  });

                  resolve();
                })
                .catch(() => {
                  reject();
                });

              return prevSelectedTableView;
            }
          });
        };

        const handleModalCancel = () => {
          setShowSaveConfim(false);
          reject();
        };

        setModalHandlers({ onSave: handleSave, onCancel: handleModalCancel });
      });
    },
    [selectedTableView, t, currentUser?.id, isNewUnsavedView, clientModuleId, fetchTableViews, toaster, addFreezeColumn],
  );

  const onApplyFilter = useCallback(
    (columnConfig: ColumnConfig, filter: FilterValue | undefined) => {
      if (!selectedTableView || !selectedTemplateFormId) return;

      setSelectedTableView((prev) => {
        if (!prev) return prev;

        const columnConfigKey = selectedTemplateFormId;

        // Clone main view columns and filters if on the main view and necessary
        if (selectedTableView.id === mainViewId) {
          const mainViewColumns = prev.columnConfigurations?.[mainViewId]?.columns ?? [];

          // Clone columns and create a new entry for the selected templateFormId
          if (!prev.columnConfigurations || !prev.columnConfigurations[selectedTemplateFormId]) {
            prev = {
              ...prev,
              columnConfigurations: {
                ...prev.columnConfigurations,
                [selectedTemplateFormId]: {
                  ...prev.columnConfigurations?.[mainViewId],
                  id: undefined,
                  enabled: true,
                  columns: mainViewColumns.map((col) => ({ ...col })),
                  sortIndex: prev.columnConfigurations?.[mainViewId]?.sortIndex ?? 0,
                  enableIndexNumbering: prev.columnConfigurations?.[mainViewId]?.enableIndexNumbering ?? false,
                },
              },
            };
          }

          prev = {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [mainViewId]: {
                ...prev.columnConfigurations?.[mainViewId],
                columns:
                  prev.columnConfigurations?.[mainViewId]?.columns.map((col) =>
                    col.value === columnConfig.value ? { ...col, filter: filter } : col,
                  ) || [],
                enabled: prev.columnConfigurations?.[mainViewId]?.enabled ?? true,
                sortIndex: prev.columnConfigurations?.[mainViewId]?.sortIndex ?? 0,
                enableIndexNumbering: prev.columnConfigurations?.[mainViewId]?.enableIndexNumbering ?? false,
              },
            },
          };
        }

        const updatedColumns =
          prev.columnConfigurations?.[columnConfigKey]?.columns.map((col) => {
            if (col.value === columnConfig.value) {
              return {
                ...col,
                filter: filter,
              };
            }
            return col;
          }) ?? [];

        return {
          ...prev,
          columnConfigurations: {
            ...prev.columnConfigurations,
            [columnConfigKey]: {
              ...prev.columnConfigurations?.[columnConfigKey],
              columns: updatedColumns,
              enabled: prev.columnConfigurations?.[columnConfigKey]?.enabled ?? true,
              enableIndexNumbering: prev.columnConfigurations?.[columnConfigKey]?.enableIndexNumbering ?? false,
            },
          },
        };
      });
    },
    [selectedTemplateFormId, selectedTableView, setSelectedTableView],
  );

  const onApplySorting = useCallback(
    (columnConfig: ColumnConfig, sortDirection: SortDirection | undefined) => {
      if (!selectedTableView || !selectedTemplateFormId) return;

      setSelectedTableView((prev) => {
        if (!prev) return prev;
        const columnConfigKey = selectedTemplateFormId;

        // Clone main view columns and filters if on the main view and necessary
        if (selectedTableView.id === mainViewId) {
          const mainViewColumns = prev.columnConfigurations?.[mainViewId]?.columns ?? [];

          // Clone columns and create a new entry for the selected templateFormId
          if (!prev.columnConfigurations || !prev.columnConfigurations[selectedTemplateFormId]) {
            prev = {
              ...prev,
              columnConfigurations: {
                ...prev.columnConfigurations,
                [selectedTemplateFormId]: {
                  ...prev.columnConfigurations?.[mainViewId],
                  id: undefined,
                  enabled: true,
                  columns: mainViewColumns.map((col) => ({ ...col })),
                  sortIndex: prev.columnConfigurations?.[mainViewId]?.sortIndex ?? 0,
                  enableIndexNumbering: prev.columnConfigurations?.[mainViewId]?.enableIndexNumbering ?? false,
                },
              },
            };
          }

          prev = {
            ...prev,
            columnConfigurations: {
              ...prev.columnConfigurations,
              [mainViewId]: {
                ...prev.columnConfigurations?.[mainViewId],
                columns:
                  prev.columnConfigurations?.[mainViewId]?.columns.map((col) =>
                    col.value === columnConfig.value ? { ...col, sortDirection: sortDirection } : col,
                  ) || [],
                enabled: prev.columnConfigurations?.[mainViewId]?.enabled ?? true,
                sortIndex: prev.columnConfigurations?.[mainViewId]?.sortIndex ?? 0,
                enableIndexNumbering: prev.columnConfigurations?.[mainViewId]?.enableIndexNumbering ?? false,
              },
            },
          };
        }

        const updatedColumns =
          prev.columnConfigurations?.[columnConfigKey]?.columns.map((col) => {
            if (col.value === columnConfig.value) {
              return {
                ...col,
                sortDirection: sortDirection,
              };
            }
            return col;
          }) ?? [];

        return {
          ...prev,
          columnConfigurations: {
            ...prev.columnConfigurations,
            [columnConfigKey]: {
              ...prev.columnConfigurations?.[columnConfigKey],
              columns: updatedColumns,
              enabled: prev.columnConfigurations?.[columnConfigKey]?.enabled ?? true,
              enableIndexNumbering: prev.columnConfigurations?.[columnConfigKey]?.enableIndexNumbering ?? false,
            },
          },
        };
      });
    },
    [selectedTemplateFormId, selectedTableView, setSelectedTableView],
  );

  const onResetFilters = useCallback(() => {
    if (!selectedOriginalTableView || !selectedTableView || !selectedTemplateFormId) return;

    if (setSelectedTableView) {
      setSelectedTableView((prev) => {
        const originalView = selectedOriginalTableView;
        const configKey = originalView.id === mainViewId ? mainViewId : selectedTemplateFormId;
        const originalColumns = originalView.columnConfigurations?.[configKey]?.columns ?? [];
        const currentColumns = prev.columnConfigurations?.[selectedTemplateFormId]?.columns ?? [];
        const resetColumns = currentColumns.map((currentCol) => {
          const originalCol = originalColumns.find((col) => col.value === currentCol.value);
          return originalCol
            ? {
                ...currentCol,
                filter: originalCol.filter,
              }
            : currentCol;
        });

        return {
          ...prev,
          columnConfigurations: {
            ...prev.columnConfigurations,
            [selectedTemplateFormId]: {
              ...prev.columnConfigurations?.[selectedTemplateFormId],
              columns: resetColumns,
              enabled: prev.columnConfigurations?.[selectedTemplateFormId]?.enabled ?? true,
              sortIndex: prev.columnConfigurations?.[selectedTemplateFormId]?.sortIndex ?? 0,
              enableIndexNumbering: prev.columnConfigurations?.[selectedTemplateFormId]?.enableIndexNumbering ?? false,
            },
          },
        };
      });
    }
  }, [selectedOriginalTableView, selectedTableView, selectedTemplateFormId]);

  const onResetSorting = useCallback(() => {
    if (!selectedOriginalTableView || !selectedTableView || !selectedTemplateFormId) return;

    if (setSelectedTableView) {
      setSelectedTableView((prev) => {
        const originalView = selectedOriginalTableView;
        const configKey = originalView.id === mainViewId ? mainViewId : selectedTemplateFormId;
        const originalColumns = originalView.columnConfigurations?.[configKey]?.columns ?? [];
        const currentColumns = prev.columnConfigurations?.[selectedTemplateFormId]?.columns ?? [];
        const resetColumns = currentColumns.map((currentCol) => {
          const originalCol = originalColumns.find((col) => col.value === currentCol.value);
          return originalCol
            ? {
                ...currentCol,
                sortDirection: originalCol.sortDirection,
              }
            : currentCol;
        });

        return {
          ...prev,
          columnConfigurations: {
            ...prev.columnConfigurations,
            [selectedTemplateFormId]: {
              ...prev.columnConfigurations?.[selectedTemplateFormId],
              columns: resetColumns,
              enabled: prev.columnConfigurations?.[selectedTemplateFormId]?.enabled ?? true,
              sortIndex: prev.columnConfigurations?.[selectedTemplateFormId]?.sortIndex ?? 0,
              enableIndexNumbering: prev.columnConfigurations?.[selectedTemplateFormId]?.enableIndexNumbering ?? false,
            },
          },
        };
      });
    }
  }, [selectedOriginalTableView, selectedTableView, selectedTemplateFormId]);

  const onUpdateTableView = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      // Doing it this way to make sure the selectedTableView is the latest,
      // because the state might have changed and the promise is resolved later
      setSelectedTableView((prevSelectedTableView) => {
        TableViewService.updateTableView(prevSelectedTableView.id, {
          name: prevSelectedTableView.name,
          userId: prevSelectedTableView.userId,
          isPersonalDefault: prevSelectedTableView.isPersonalDefault,
          isOrganisationDefault: prevSelectedTableView.isOrganisationDefault,
          columnConfigurations: prevSelectedTableView.columnConfigurations
            ? Object.entries(prevSelectedTableView.columnConfigurations).map(([templateFormId, config]) => ({
                templateFormId,
                ...config,
                columns: config.columns.filter(({ value }) => value !== freezeLineId),
              }))
            : [],
        })
          .then((response) => {
            const updatedColumnConfigurations = response.data.columnConfigurations;
            const updatedTableView = {
              ...prevSelectedTableView,
              columnConfigurations: updatedColumnConfigurations.reduce((acc: Record<string, TableViewColumnConfiguration>, config) => {
                acc[config.templateFormId] = { ...config, columns: addFreezeColumn(config) };
                return acc;
              }, {}),
            };
            fetchTableViews();
            setSelectedTableView(ObjectUtils.DeepClone(updatedTableView));
            setSelectedOriginalTableView(ObjectUtils.DeepClone(updatedTableView));

            toaster.addToast({
              title: t('toasters.view.save.success.title'),
              description: t('toasters.view.save.success.description', { name: updatedTableView.name }),
              type: ToastType.SUCCESS,
              expiresInMs: 5000,
            });

            resolve();
          })
          .catch(() => {
            reject();
          });

        return prevSelectedTableView;
      });
    });
  }, [addFreezeColumn, fetchTableViews, t, toaster]);

  const contextValues = useMemo<TableViewContextType>(
    () => ({
      clientModuleId: clientModuleId,
      clientModuleSectionId: clientModuleSectionId,
      templateModuleSectionId: templateModuleSectionId,
      clientModuleSectionName: sectionName,
      availableViews: availableViews,
      isDirty: isDirty,
      setAvailableViews: setAvailableViews,
      fetchTableViews: fetchTableViews,
      selectedOriginalTableView: selectedOriginalTableView,
      setSelectedOriginalTableView: setSelectedOriginalTableView,
      selectedTableView: selectedTableView,
      setSelectedTableView: setSelectedTableView,
      onEditTableView: onEditTableView,
      onDiscardTableView: onDiscardTableView,
      onSaveTableView: onSaveTableView,
      selectedTemplateFormId,
      setSelectedTemplateFormId,
      resultCounts,
      setResultCounts,
      onApplyFilter,
      onApplySorting,
      onResetFilters,
      onResetSorting,
      exportProvider,
      clientModule,
      showRowNumbers,
      setShowRowNumbers,
      renderedGQL,
      setRenderedGQL,
      onUpdateTableView,
    }),
    [
      onUpdateTableView,
      clientModuleId,
      clientModuleSectionId,
      templateModuleSectionId,
      sectionName,
      availableViews,
      isDirty,
      fetchTableViews,
      selectedOriginalTableView,
      selectedTableView,
      onEditTableView,
      onDiscardTableView,
      onSaveTableView,
      selectedTemplateFormId,
      resultCounts,
      onApplyFilter,
      onApplySorting,
      onResetFilters,
      onResetSorting,
      exportProvider,
      clientModule,
      showRowNumbers,
      renderedGQL,
      setRenderedGQL,
    ],
  );

  useEffect(() => {
    setAvailableViews([mainView]);
  }, [mainView]);

  const setPersonalViewFor = useCallback(() => {
    setSelectedTableView((prev) => ({ ...prev, userId: currentUser?.id, isOrganisationDefault: false }));
  }, [currentUser?.id]);

  const setOrgViewFor = useCallback(() => {
    setSelectedTableView((prev) => ({ ...prev, userId: undefined, isOrganisationDefault: selectedOriginalTableView.isOrganisationDefault }));
  }, [selectedOriginalTableView.isOrganisationDefault]);

  const setSaveAsPersonalDefault = useCallback((value: boolean) => {
    setSelectedTableView((prev) => ({ ...prev, isPersonalDefault: value }));
  }, []);

  const setSaveAsOrgDefault = useCallback((value: boolean) => {
    setSelectedTableView((prev) => ({ ...prev, isOrganisationDefault: value }));
  }, []);

  const [columnWidths, setColumnWidths] = useState<number[]>([]);
  const [scrollContainerRef, setScrollContainerRef] = useState<React.RefObject<HTMLDivElement>>(() => ({ current: null }));
  const tableViewContainerRef = useRef<HTMLDivElement>(null);

  return (
    <TableViewContext.Provider value={contextValues}>
      {TabsSlot()}

      <div className="flex flex-col">
        <TableViewScrollProvider columnWidths={columnWidths} scrollContainerRef={scrollContainerRef}>
          <TableViewFilterProvider>
            <TableViewTopContainer>{NewButtonSlot()}</TableViewTopContainer>
            <div className="mx-3 flex-grow border" ref={tableViewContainerRef}>
              <div
                className="flex flex-col items-center"
                style={{ height: Math.max(300, innerHeight - (tableViewContainerRef.current?.offsetTop || 0) - 215) }}
              >
                {(!isNewUnsavedView || (isNewUnsavedView && !!selectedTableView.columnConfigurations)) && (
                  <TableViewData
                    clientModuleId={clientModuleId}
                    view={selectedTableView}
                    setScrollContainerRef={setScrollContainerRef}
                    setColumnWidths={setColumnWidths}
                  />
                )}
                {isNewUnsavedView && !selectedTableView.columnConfigurations && <EmptyView />}
              </div>
            </div>
          </TableViewFilterProvider>
        </TableViewScrollProvider>
      </div>
      <ModalContext.Provider value={{ open: showDiscardConfim, onClose: () => setShowDiscardConfim(false), modalWidth: 'w-2/5' }}>
        <ConfirmationModal
          title={t('discard-changes.title')}
          description={t('discard-changes.description')}
          confirmText={t('discard-changes.confirm')}
          onConfirm={doDiscard}
          onCancel={() => setShowDiscardConfim(false)}
        />
      </ModalContext.Provider>
      {selectedTableView && (
        <>
          <TableViewConfigModal
            open={showTableViewConfigModal}
            onClose={() => setShowTableViewConfigModal(false)}
            onApplyChanges={() => setShowTableViewConfigModal(false)}
          />
          <ModalContext.Provider value={{ open: showSaveConfim, onClose: () => setShowSaveConfim(false), modalWidth: 'w-2/5' }}>
            <StandardModal
              title={t('save-view.title', { view: selectedTableView.name })}
              confirmButtonTitle={t('save-view.confirm')}
              onConfirmClick={modalHandlers.onSave}
              onCancelClick={modalHandlers.onCancel}
            >
              <Input
                placeholder={t('save-view.name-placeholder')}
                label={t('save-view.name-label')}
                value={selectedTableView.name}
                onChange={(e) => setSelectedTableView((prev) => ({ ...prev, name: e.target.value }))}
              />
              {isTeamLead && (
                <>
                  <div className="mt-8">
                    <span className="font-medium">{t('save-view.as.label')}</span>
                  </div>
                  <div className="flex flex-col gap-1 py-2 pl-1">
                    <RadioButton value={!!selectedTableView.userId} label={t('save-view.as.personal')} size="small" onChange={setPersonalViewFor} />
                    <div className="flex items-center gap-1">
                      <RadioButton value={!selectedTableView.userId} label={t('save-view.as.org')} size="small" onChange={setOrgViewFor} />
                      <Tooltip text={t('save-view.as.org-info', { client: client?.name })}>
                        {(tooltip) => (
                          <div {...tooltip}>
                            <HelpCircleIcon className="h-5 w-5" />
                          </div>
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </>
              )}
              <div className="mt-2">
                <span className="font-medium">{t('save-view.preferences.label')}</span>
              </div>
              <div className="flex w-full items-start justify-between py-2 pl-1">
                <Checkbox
                  value={selectedTableView.isPersonalDefault}
                  onChange={setSaveAsPersonalDefault}
                  label={t('save-view.preferences.personal.title')}
                  description={t('save-view.preferences.personal.description')}
                />
              </div>
              {!selectedTableView.userId && (
                <div className="flex w-full items-start justify-between py-2 pl-1">
                  <Checkbox
                    value={selectedTableView.isOrganisationDefault}
                    onChange={setSaveAsOrgDefault}
                    label={t('save-view.preferences.org.title')}
                    description={t('save-view.preferences.org.description')}
                  />
                </div>
              )}
            </StandardModal>
          </ModalContext.Provider>
        </>
      )}

      <RouteGuard
        when={isDirty}
        forceClose={showSaveConfim}
        onContinue={() => Promise.resolve(setSelectedTableView(selectedOriginalTableView))}
        onSave={async () => {
          try {
            let saveOrUpdate;
            if (selectedTableView.id === newViewId || selectedTableView.id === mainViewId) {
              saveOrUpdate = () => onSaveTableView();
            } else if (!isTeamLead && selectedTableView.userId) {
              saveOrUpdate = () => onUpdateTableView();
            } else {
              if (isTeamLead) {
                saveOrUpdate = () => onUpdateTableView();
              } else {
                saveOrUpdate = () => onSaveTableView(true);
              }
            }

            await saveOrUpdate();
            return true;
          } catch {
            return false;
          }
        }}
      />
    </TableViewContext.Provider>
  );
});

export default TableViewProvider;
