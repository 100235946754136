import { useMemo, useState, useCallback, useEffect } from 'react';
import { Item, SelectListMenu } from '../shared/SelectListMenu';
import { ChevronIcon, ChevronType } from '../shared/icon/ChevronIcon';
import { mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import PlusIcon from '../shared/icon/PlusIcon';
import IconRender from '../shared/icon/IconRender';
import { useTableView } from '../../contexts/table-view/TableViewContext';
import { freezeLineId, mainViewId, newViewId } from '../../models/TableView';
import { TableView, TableViewColumnConfiguration } from '../../models/TableView';
import { useTranslation } from 'react-i18next';
import TableViewService from '../../services/TableViewService';
import Tooltip from '../shared/Tooltip';
import usePermissions from '../../hooks/permissions/usePermissions';
import { Roles } from '../../models/Role';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { ModalContext } from '../../contexts/ModalContext';
import ObjectUtils from '../../utils/ObjectUtils';
import { Input, InputStyle } from '../shared/form-control/Input';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';

const subMenuItems = {
  setAsDefaultView: { id: 'set-as-default-view', iconName: 'Home' },
  setAsDefaultViewForMe: { id: 'set-as-default-view-for-me' },
  setAsDefaultViewForOrg: { id: 'set-as-default-view-for-org' },
  addToFavourites: { id: 'add-to-favourites', iconName: 'Star' },
  renameView: { id: 'rename-view', iconName: 'Edit' },
  duplicateView: { id: 'duplicate-view', iconName: 'Copy' },
  download: { id: 'download', iconName: 'Download' },
  deleteView: { id: 'delete-view', iconName: 'Trash' },
};

const TableViewMenu = () => {
  const {
    clientModuleSectionId,
    templateModuleSectionId,
    availableViews,
    setSelectedOriginalTableView,
    setSelectedTableView,
    selectedTableView,
    isDirty,
    onDiscardTableView,
    onSaveTableView,
    onUpdateTableView,
    onEditTableView,
    setAvailableViews,
    fetchTableViews,
  } = useTableView();
  const { t } = useTranslation(['table-view']);
  const [isOpen, setIsOpen] = useState(false);
  const canAddNewView = useMemo(() => selectedTableView?.id !== newViewId && !isDirty, [isDirty, selectedTableView?.id]);
  const currentClient = useCurrentClient((x) => x.value);
  const currentUser = useCurrentUser((x) => x.value);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<[boolean, string]>([false, '']);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<[boolean, Item | null]>([false, null]);
  const [menuOptions, setMenuOptions] = useState<Item[]>([]);
  const hasPermission = usePermissions();
  const isTeamLead = hasPermission(Roles.TeamLead);

  useEffect(() => {
    let personalDividerAdded = false;
    const isTeamLead = hasPermission(Roles.TeamLead);

    // Sort views based on the following:
    // 1. Non-personal views (no userId): isDefault first, then isFavourite
    // 2. Personal views (with userId): isDefault first, then isFavourite
    const sortedViews = [...availableViews].sort((a, b) => {
      // Sort personal and non-personal separately
      const aIsPersonal = !!a.userId;
      const bIsPersonal = !!b.userId;

      if (!aIsPersonal && bIsPersonal) return -1; // Non-personal views first
      if (aIsPersonal && !bIsPersonal) return 1; // Personal views next

      // For non-personal views (no userId), sort by isDefault then isFavourite
      if (!aIsPersonal && !bIsPersonal) {
        if (a.isDefault && !b.isDefault) return -1;
        if (!a.isDefault && b.isDefault) return 1;
        // My default view should be first
        if (!a.isOrganisationDefault && b.isOrganisationDefault) return -1;
        if (a.isOrganisationDefault && !b.isOrganisationDefault) return 1;
        if (a.isFavourite && !b.isFavourite) return -1;
        if (!a.isFavourite && b.isFavourite) return 1;
        if (a.name && b.name) return a.name.localeCompare(b.name);
        return 0;
      }

      // For personal views (with userId), sort by isDefault then isFavourite
      if (aIsPersonal && bIsPersonal) {
        if (a.isDefault && !b.isDefault) return -1;
        if (!a.isDefault && b.isDefault) return 1;
        if (a.isFavourite && !b.isFavourite) return -1;
        if (!a.isFavourite && b.isFavourite) return 1;
        if (a.name && b.name) return a.name.localeCompare(b.name);
        return 0;
      }

      return 0;
    });

    const defaultViews = sortedViews.filter((view) => view.isDefault);
    const hasMultipleDefaults = defaultViews.length > 1;

    const menuItems = sortedViews.map((view) => {
      let iconName;
      let isActiveDefault = false;

      if (view.isDefault) {
        if (hasMultipleDefaults && !view.isOrganisationDefault) {
          iconName = 'Home';
          isActiveDefault = true;
        } else if (!hasMultipleDefaults) {
          iconName = 'Home';
          isActiveDefault = true;
        } else {
          iconName = view.isFavourite ? 'Star' : 'Building2';
        }
      } else {
        // If the mainViewId is not the default, it should get the Star icon
        if (view.id === mainViewId && !view.isDefault) {
          iconName = 'Star';
        } else {
          iconName = view.isFavourite ? 'Star' : 'View';
        }
      }

      const insufficientDeletePermissions = view.id !== mainViewId && !view.userId && !isTeamLead;

      const viewMenuItem = {
        id: view.id,
        value: view.id,
        text: view.name!,
        iconName,
        children:
          view.id === newViewId
            ? undefined
            : [
                {
                  ...subMenuItems.setAsDefaultView,
                  text: t(`menu.sub-menu.set-as-default-view`),
                  onClickShowChildren: isTeamLead,
                  value: view.id,
                  disabled: view.isDefault && !isTeamLead,
                  disabledTooltip: t('menu.tooltips.disabled-set-as-default-view'),
                  children: isTeamLead
                    ? [
                        {
                          ...subMenuItems.setAsDefaultViewForOrg,
                          text: t('menu.sub-menu.set-as-default-view-for-org'),
                          value: view.id,
                          disabled:
                            (view.isDefault && view.isOrganisationDefault) ||
                            (view.id === mainViewId && !sortedViews.find((v) => v.isDefault && v.isOrganisationDefault)),
                          disabledTooltip: t('menu.tooltips.disabled-set-as-default-view'),
                        },
                        {
                          ...subMenuItems.setAsDefaultViewForMe,
                          text: t('menu.sub-menu.set-as-default-view-for-me'),
                          value: view.id,
                          disabled: view.isDefault && !view.isOrganisationDefault,
                          disabledTooltip: t('menu.tooltips.disabled-set-as-default-view'),
                        },
                      ]
                    : undefined,
                },
                {
                  ...subMenuItems.addToFavourites,
                  text: view.isFavourite ? t('menu.sub-menu.remove-from-favourites') : t(`menu.sub-menu.add-to-favourites`),
                  disabled: view.id === mainViewId || isActiveDefault,
                  value: view.id,
                  disabledTooltip: isActiveDefault
                    ? t('menu.tooltips.disabled-default-set-as-favourite')
                    : t('menu.tooltips.disabled-main-set-as-favourite'),
                },
                {
                  ...subMenuItems.renameView,
                  text: t(`menu.sub-menu.rename-view`),
                  disabled: view.id === mainViewId,
                  value: view.id,
                  disabledTooltip: t('menu.tooltips.disabled-main-rename'),
                },
                {
                  ...subMenuItems.duplicateView,
                  text: t(`menu.sub-menu.duplicate-view`),
                  disabled: view.id === mainViewId,
                  value: view.id,
                  disabledTooltip: t('menu.tooltips.disabled-main-duplicate'),
                },
                // { ...subMenuItems.download, text: t(`menu.sub-menu.download`), value: view.id },
                {
                  ...subMenuItems.deleteView,
                  text: t(`menu.sub-menu.delete-view`),
                  disabled: view.id === mainViewId || insufficientDeletePermissions,
                  value: view.id,
                  disabledTooltip: insufficientDeletePermissions
                    ? t('menu.tooltips.disabled-main-delete-permission')
                    : t('menu.tooltips.disabled-main-delete'),
                },
              ],
      };

      const items = [];

      if (view.userId && !personalDividerAdded) {
        items.push({
          id: 'personal-divider',
          value: 'personal-divider',
          text: t('menu.personal'),
          hasDivider: true,
        });
        personalDividerAdded = true;
      }

      items.push(viewMenuItem);
      return items;
    });

    const organisationDivider = {
      id: 'orginisation-divider',
      value: 'orginisation-divider',
      text: t('menu.organisation'),
      hasDivider: true,
    };
    setMenuOptions([organisationDivider, ...menuItems.flat()]);
  }, [availableViews, hasPermission, t]);

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onAddNewViewClick = useCallback(() => {
    const newView: TableView = {
      id: newViewId,
      name: t('views.new-view'),
      templateModuleSectionId: templateModuleSectionId,
      clientId: currentClient?.id || '',
      userId: currentUser?.id,
      columnConfigurations: null,
      isDefault: false,
      isFavourite: false,
      isOrganisationDefault: false,
      isPersonalDefault: false,
      defaults: [],
    };
    setSelectedTableView && setSelectedTableView(newView);
    toggleMenu();
    onEditTableView();
  }, [currentClient?.id, currentUser?.id, onEditTableView, setSelectedTableView, t, templateModuleSectionId, toggleMenu]);

  const [viewToRename, setViewToRename] = useState<TableView | null>(null);

  const renameView = useCallback(() => {
    if (viewToRename) {
      TableViewService.updateTableView(viewToRename.id, {
        name: viewToRename.name,
        userId: viewToRename.userId,
        isPersonalDefault: viewToRename.isPersonalDefault,
        isOrganisationDefault: viewToRename.isOrganisationDefault,
        columnConfigurations: viewToRename.columnConfigurations
          ? Object.entries(viewToRename.columnConfigurations).map(([templateFormId, config]) => ({
              templateFormId: templateFormId,
              ...config,
              columns: config.columns.filter(({ value }) => value !== freezeLineId),
            }))
          : [],
      }).then(() => {
        setAvailableViews &&
          setAvailableViews((prev) =>
            prev.map((v) => {
              if (v.id === viewToRename.id) {
                return { ...v, name: viewToRename.name };
              }
              return v;
            }),
          );
        if (selectedTableView?.id === viewToRename.id) {
          setSelectedTableView && setSelectedTableView(ObjectUtils.DeepClone(viewToRename));
          setSelectedOriginalTableView && setSelectedOriginalTableView(ObjectUtils.DeepClone(viewToRename));
        }
        setViewToRename(null);
      });
    }
  }, [selectedTableView?.id, setAvailableViews, setSelectedOriginalTableView, setSelectedTableView, viewToRename]);

  const customListRenderer = useCallback(
    (item: Item) => (
      <div key={item.id} className="mr-1 flex items-center truncate">
        {item.iconName && <IconRender className="mr-2 h-5 w-5 flex-shrink-0" type={item.iconName as string} />}

        <div className="min-w-0 max-w-full flex-grow truncate">
          {item.id !== viewToRename?.id && item.text}
          {item.id === viewToRename?.id && (
            <Input
              value={viewToRename.name}
              onBlur={renameView}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  renameView();
                }
              }}
              onChange={(event) => {
                const view = availableViews.find((v) => v.id === item.id);
                if (view) {
                  setViewToRename &&
                    setViewToRename({
                      ...view,
                      name: event.target.value,
                    });
                }
              }}
              style={InputStyle.MINIMAL}
              autoFocus
            />
          )}
        </div>
      </div>
    ),
    [availableViews, renameView, viewToRename?.id, viewToRename?.name],
  );

  const footerRenderer = useMemo(
    () => (
      <>
        <div
          className={`flex w-full cursor-pointer select-none items-center px-3 py-2 hover:bg-gray-100 hover:text-black ${!canAddNewView && 'pointer-events-none opacity-40'}`}
          {...mouseAndKeyboardCallbackProps(() => onAddNewViewClick())}
        >
          <PlusIcon className="mr-2 h-4 w-4" />
          <div className="flex-grow">{t('menu.add-new-view')}</div>
        </div>
        {!canAddNewView && (
          <div className="flex justify-between px-3 py-2">
            <span
              className="cursor-pointer font-medium underline-offset-2 hover:underline"
              {...mouseAndKeyboardCallbackProps(() => {
                // Logic: If the view is new or main view, save it. If it's a personal view, update it. Otherwise, save it as new.
                // Permissions: Team leads can save any view. Regular users can only save personal views.
                if (selectedTableView.id === newViewId || selectedTableView.id === mainViewId) {
                  onSaveTableView();
                } else if (!isTeamLead && selectedTableView.userId) {
                  onUpdateTableView();
                } else {
                  if (isTeamLead) {
                    onUpdateTableView();
                  } else {
                    onSaveTableView(true);
                  }
                }
                toggleMenu();
              })}
            >
              {t('buttons.save-current-view')}
            </span>
            <span
              className="text-semantic-2 cursor-pointer font-medium underline-offset-2 hover:underline"
              {...mouseAndKeyboardCallbackProps(() => {
                onDiscardTableView();
                toggleMenu();
              })}
            >
              {t('buttons.discard')}
            </span>
          </div>
        )}
      </>
    ),
    [
      canAddNewView,
      isTeamLead,
      onAddNewViewClick,
      onDiscardTableView,
      onSaveTableView,
      onUpdateTableView,
      selectedTableView.id,
      selectedTableView.userId,
      t,
      toggleMenu,
    ],
  );

  const onMenuClose = useCallback(() => {
    setIsOpen(false);
    if (viewToRename) {
      renameView();
    }
  }, [renameView, viewToRename]);

  const setViewAsDefault = useCallback(
    (option: Item, me: boolean) => {
      const view = availableViews.find((v) => v.id === option.value);
      if (view && currentClient) {
        TableViewService.setAsDefaultView({
          tableViewId: view.id,
          clientModuleSectionId: clientModuleSectionId!,
          clientId: currentClient.id,
          userId: me ? currentUser?.id : undefined, // If personal default, pass userId
        })
          .then(() => {
            fetchTableViews();
          })
          .finally(() => {
            onMenuClose();
          });
      }
    },
    [availableViews, clientModuleSectionId, currentClient, currentUser?.id, fetchTableViews, onMenuClose],
  );

  const toggleViewAsFavourite = useCallback(
    (option: Item) => {
      const view = availableViews.find((v) => v.id === option.value);
      if (view) {
        if (!view.isFavourite) {
          TableViewService.setAsFavouriteView(view.id).then(() => {
            setAvailableViews &&
              setAvailableViews((prev) => {
                return prev.map((v) => {
                  if (v.id === view.id) {
                    return { ...v, isFavourite: true };
                  }
                  return v;
                });
              });
          });
        } else {
          TableViewService.deleteAsFavouriteView(view.id).then(() => {
            setAvailableViews &&
              setAvailableViews((prev) => {
                return prev.map((v) => {
                  if (v.id === view.id) {
                    return { ...v, isFavourite: false };
                  }
                  return v;
                });
              });
          });
        }
      }
    },
    [availableViews, setAvailableViews],
  );

  const duplicateView = useCallback(
    (option: Item) => {
      const view = availableViews.find((v) => v.id === option.value);
      if (view) {
        const clonedView = ObjectUtils.DeepClone(view);

        const clonedColumnConfigs = clonedView.columnConfigurations
          ? Object.keys(clonedView.columnConfigurations).reduce(
              (acc, key) => {
                acc[key] = { ...clonedView.columnConfigurations![key], id: undefined };
                return acc;
              },
              {} as Record<string, TableViewColumnConfiguration>,
            )
          : null;

        const newView: TableView = {
          ...clonedView,
          columnConfigurations: clonedColumnConfigs,
          id: newViewId,
          name: t('views.copy-of-view', { name: clonedView.name }),
          isDefault: false,
          isFavourite: false,
          isOrganisationDefault: false,
          isPersonalDefault: false,
          defaults: [],
        };

        setSelectedTableView && setSelectedTableView(newView);
        onMenuClose();
      }
    },
    [availableViews, onMenuClose, setSelectedTableView, t],
  );

  const deleteViewConfirm = useCallback(
    (id: string) => {
      const view = availableViews.find((v) => v.id === id);
      if (view) {
        TableViewService.deleteTableView(view.id)
          .then(() => {
            setAvailableViews &&
              setAvailableViews((prev) => {
                return prev.filter((v) => v.id !== view.id);
              });

            if (selectedTableView?.id === view.id) {
              setSelectedTableView && setSelectedTableView(ObjectUtils.DeepClone(availableViews[0]));
              setSelectedOriginalTableView && setSelectedOriginalTableView(ObjectUtils.DeepClone(availableViews[0]));
            }
          })
          .finally(() => {
            setShowDeleteConfirm([false, '']);
          });
      }
    },
    [availableViews, selectedTableView?.id, setAvailableViews, setSelectedOriginalTableView, setSelectedTableView],
  );

  const menuActions = useMemo(
    () => ({
      [subMenuItems.setAsDefaultView.id]: (option: Item) => setViewAsDefault(option, true),
      [subMenuItems.setAsDefaultViewForMe.id]: (option: Item) => setViewAsDefault(option, true),
      [subMenuItems.setAsDefaultViewForOrg.id]: (option: Item) => setViewAsDefault(option, false),
      [subMenuItems.addToFavourites.id]: (option: Item) => toggleViewAsFavourite(option),
      [subMenuItems.renameView.id]: (option: Item) => setViewToRename(availableViews.find((v) => v.id === option.value) || null),
      [subMenuItems.duplicateView.id]: (option: Item) => duplicateView(option),
      // [subMenuItems.download.id]: () => console.log('Downloading view'),
      [subMenuItems.deleteView.id]: (option: Item) => {
        setShowDeleteConfirm([true, option.value as string]);
        onMenuClose();
      },
    }),
    [availableViews, duplicateView, onMenuClose, setViewAsDefault, toggleViewAsFavourite],
  );

  const onSelectView = useCallback(
    (item: Item) => {
      const selectedView = availableViews.find((view) => view.id === item.id);
      if (selectedView) {
        setSelectedTableView && setSelectedTableView(ObjectUtils.DeepClone(selectedView));
        setSelectedOriginalTableView && setSelectedOriginalTableView(ObjectUtils.DeepClone(selectedView));
      }
    },
    [availableViews, setSelectedOriginalTableView, setSelectedTableView],
  );

  const handleMenuItemClick = useCallback(
    (option: Item) => {
      const action = menuActions[option.id];
      if (action) {
        action(option);
      } else {
        if (!isDirty) {
          onSelectView(option);
        } else {
          setShowUnsavedChangesModal([true, option]);
        }
        onMenuClose();
      }
    },
    [isDirty, menuActions, onMenuClose, onSelectView],
  );

  return (
    <div>
      <SelectListMenu
        options={menuOptions}
        isOpen={isOpen}
        onBlur={onMenuClose}
        blurOnClick={false}
        onClick={handleMenuItemClick}
        enableSearching={menuOptions.length > 10}
        customListItemRenderer={customListRenderer}
        footer={footerRenderer}
        placement="bottom-start"
        className={`${!canAddNewView ? '!min-w-72' : '!min-w-64'} max-h-[450px]`}
        selectedIndex={menuOptions.findIndex((option) => option.value === selectedTableView?.id)}
        disableTooltipIndex={menuOptions.findIndex((option) => option.value === viewToRename?.id)}
      >
        {(triggerProps) => (
          <div
            {...triggerProps}
            className="border-primary-1 relative mr-2 inline-flex h-[30px] max-w-64 cursor-pointer flex-row items-center rounded-md border-2 px-3"
            {...mouseAndKeyboardCallbackProps(toggleMenu)}
          >
            <Tooltip text={selectedTableView.name} truncatedTextMode>
              {(tooltip) => (
                <div {...tooltip} className="min-w-0 max-w-full truncate">
                  {selectedTableView && selectedTableView.name}
                </div>
              )}
            </Tooltip>
            <ChevronIcon type={isOpen ? ChevronType.UP : ChevronType.DOWN} className="ml-2 h-4 w-4" />
          </div>
        )}
      </SelectListMenu>
      <ModalContext.Provider value={{ open: showDeleteConfirm[0] }}>
        <ConfirmationModal
          title={t('delete-confirm.title')}
          description={t('delete-confirm.description')}
          confirmText={t('delete-confirm.confirm')}
          onConfirm={() => deleteViewConfirm(showDeleteConfirm[1])}
          onCancel={() => setShowDeleteConfirm([false, ''])}
          alt
        />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={{ open: showUnsavedChangesModal[0], onClose: () => setShowUnsavedChangesModal([false, null]), modalWidth: 'w-2/5' }}
      >
        <ConfirmationModal
          title={t('unsaved-changes.title')}
          description={t('unsaved-changes.description')}
          cancelText={t('unsaved-changes.discard')}
          confirmText={t('unsaved-changes.confirm')}
          onConfirm={() => {
            setShowUnsavedChangesModal([false, null]);
            let saveOrUpdate;
            if (selectedTableView.id === newViewId || selectedTableView.id === mainViewId) {
              saveOrUpdate = () => onSaveTableView();
            } else if (!isTeamLead && selectedTableView.userId) {
              saveOrUpdate = () => onUpdateTableView();
            } else {
              if (isTeamLead) {
                saveOrUpdate = () => onUpdateTableView();
              } else {
                saveOrUpdate = () => onSaveTableView(true);
              }
            }

            saveOrUpdate().then(() => {
              onSelectView(showUnsavedChangesModal[1]!);
            });
          }}
          onCancel={() => {
            onSelectView(showUnsavedChangesModal[1]!);
            setShowUnsavedChangesModal([false, null]);
          }}
        />
      </ModalContext.Provider>
    </div>
  );
};

export default TableViewMenu;
