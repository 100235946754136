import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventSystem } from '../../events/EventSystem';
import { ClientForm } from '../../models/ClientForm';
import { ClientFormDefault } from '../../models/ClientFormDefaults';
import { FormConfig } from '../../models/Form';
import ClientTemplateModuleService from '../../services/ClientTemplateModuleService';
import LanguageUtils from '../../utils/LanguageUtils';
import { SupportedLanguage } from '../../types/Languages';
import { Option } from '../Option';
import NewTaskModal from '../tasks/NewTaskModal';
import { ButtonSize } from './form-control/Button';
import DropdownButton from './form-control/DropdownButton';
import { useSearchParams } from 'react-router-dom';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import { interpolateActionData } from '../../utils/interpolation/ActionDataInterpolator';
import { useCurrentClient } from '../../global-state/Clients';

type NewTaskButtonProps = {
  moduleId?: string;
  moduleTemplateId?: string;
  onTaskCreated: (clientForm: ClientForm) => void;
};

const NewTaskButton: FC<NewTaskButtonProps> = (props) => {
  const { onTaskCreated, moduleTemplateId, moduleId } = props;
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [formOptions, setFormOptions] = useState<Option<string, string>[]>([]);
  const [formDefaults, setFormDefaults] = useState<ClientFormDefault[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Option<string, string> | null>(null);
  const [searchParams] = useSearchParams();
  const { match: currentRouteInfo } = useCurrentRoute();
  const modulePageId = useMemo(() => currentRouteInfo?.params.moduleId || '', [currentRouteInfo?.params.moduleId]);

  const currentClient = useCurrentClient((x) => x.value);
  const { t } = useTranslation('task-list');

  useEffect(
    function triggerModalFromUrl() {
      const templateId = searchParams.get('import');
      if (!templateId) {
        return;
      }

      const templateOption = formOptions.find((x) => x.id === templateId);
      if (!templateOption) {
        return;
      }

      // Note, not removing param from url in this component, since the new task modal
      // needs to set its internal state still to show the import mode
      setSelectedTemplate(templateOption);
      setShowTaskModal(true);
    },
    [formOptions, searchParams],
  );

  const clientTemplateModuleService = useMemo(() => currentClient && new ClientTemplateModuleService(currentClient.id), [currentClient]);
  const selectedConfiguration = useMemo(
    () => formDefaults.find((x) => x.templateForm.id === selectedTemplate?.id)?.defaults?.configuration,
    [formDefaults, selectedTemplate?.id],
  );

  const getFormDefaults = useCallback(() => {
    if (moduleTemplateId) {
      return clientTemplateModuleService?.getTemplateFormDefaults({ pageNumber: 1, pageSize: 999, moduleId: moduleTemplateId });
    }
    if (modulePageId) {
      return clientTemplateModuleService?.getAllTemplateFormDefaults({ pageNumber: 1, pageSize: 999, moduleId: modulePageId });
    }
    return clientTemplateModuleService?.getAllTemplateFormDefaults({ pageNumber: 1, pageSize: 999 });
  }, [clientTemplateModuleService, moduleTemplateId, modulePageId]);

  useEffect(() => {
    getFormDefaults()?.then((res) => {
      setFormDefaults(res.data);
    });
  }, [getFormDefaults]);

  useEffect(
    function setOptions() {
      const handler = (lang?: SupportedLanguage) => {
        setFormOptions(
          formDefaults.map((formDefault) => ({
            id: (formDefault.templateForm as FormConfig).id,
            value: (formDefault.templateForm as FormConfig).clientId || '',
            text: LanguageUtils.getTranslation('title', (formDefault.templateForm as FormConfig).translations, lang || null),
          })),
        );
      };
      handler();

      EventSystem.listen('language-changed', handler);
      return () => EventSystem.stopListening('language-changed', handler);
    },
    [formDefaults],
  );

  const selectedTemplatePredefinedSubtitle = useMemo(() => {
    const template = formDefaults.find((x) => x.templateForm.id === selectedTemplate?.id)?.templateForm;
    const text = template?.translations && LanguageUtils.getTranslation('predefinedTitle', template?.translations);

    return text && interpolateActionData(text, template?.placeholders || []);
  }, [formDefaults, selectedTemplate?.id]);

  return (
    <>
      <DropdownButton
        data-cy="show-new-form-modal"
        onSelect={(o) => {
          setSelectedTemplate(o as Option<string, string>);
          setShowTaskModal(true);
        }}
        className="flex-shrink-0"
        options={formOptions}
        ignoreMinWidth
        size={ButtonSize.S}
      >
        {t('buttons.add')}
      </DropdownButton>
      {selectedTemplate && (
        <NewTaskModal
          templateId={selectedTemplate.id}
          templateFormName={selectedTemplate.text}
          templateClient={selectedTemplate.value || null}
          predefinedSubtitle={selectedTemplatePredefinedSubtitle}
          modalHeader={selectedTemplate.text}
          moduleId={moduleId}
          onClose={() => {
            setShowTaskModal(false);
          }}
          open={showTaskModal}
          onCreated={onTaskCreated}
          defaults={selectedConfiguration}
          enableImporting
          onRetry={() => setShowTaskModal(true)}
        />
      )}
    </>
  );
};

export default NewTaskButton;
