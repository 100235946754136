import { FC, memo, useMemo } from 'react';
import { ImageSize, ProfileImageStack } from '../../ownership/ProfileImageStack';
import { ClientFormUserRole, ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';

type Prop = {
  userId: string;
  role: string;
  formSectionId?: string;
  acceptedStatus?: boolean;
  sortOrder?: number;
  requiresAction?: boolean;
  pbkUser: { firstName: string; lastName: string; userImageId: string };
};

export const GraphQLRoleToPlatformRole: Record<string, ClientFormUserRoleValues> = {
  OWNER: ClientFormUserRole.Owner,
  VALIDATOR: ClientFormUserRole.Validator,
  APPROVER: ClientFormUserRole.Approver,
  CONTRIBUTOR: ClientFormUserRole.Contributor,
  VIEWER: ClientFormUserRole.Viewer,
};

const MembersRenderer: FC<{ data: Prop[] | Prop }> = (props) => {
  const data = useMemo(() => (Array.isArray(props.data) ? props.data : [props.data]), [props.data]);

  const members = useMemo(
    () =>
      [...data]
        .sort((a, b) => {
          // sort null formSectionIds first
          if (a.formSectionId && !b.formSectionId) {
            return 1;
          }
          if (!a.formSectionId && b.formSectionId) {
            return -1;
          }
          return 0;
        })
        .map((x) => ({
          id: x.userId,
          firstName: x.pbkUser?.firstName ?? '',
          lastName: x.pbkUser?.lastName ?? '',
          role: GraphQLRoleToPlatformRole[x.role],
          userImageId: x.pbkUser?.userImageId,
          acceptedStatus: x.acceptedStatus,
          sortOrder: x.sortOrder,
          formSectionId: x.formSectionId,
          requiresAction: x.requiresAction,
        })),
    [data],
  );

  if (members.length === 0) return '-';

  return <ProfileImageStack users={members} size={ImageSize.XS} />;
};

export default memo(MembersRenderer);
